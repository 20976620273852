import { useTranslation } from 'react-i18next';
import ScrollToTop from '../components/ScrollToTop';
import useDocumentTitle from "../hooks/useDocumentTitle";
import AboutAndOperationSection from "../sections/AboutAndOperationSection";
import HeroSection from "../sections/HeroSection";
import MethodologiesSection from "../sections/MethodologiesSection";
import OurPilarsSection from "../sections/OurPilarsSection";

function Home() {
	const { t } = useTranslation();
	useDocumentTitle(t('home'));

	return (
		<div>
			<ScrollToTop />

			<HeroSection />

			<AboutAndOperationSection />

			<MethodologiesSection />

			<OurPilarsSection />
		</div>
	);
}

export default Home;
