import facebookLogo from '../assets/svg/facebook.svg';
import facebookBlueLogo from '../assets/svg/facebookBlueIcon.svg';
import linkedinLogo from '../assets/svg/linkedin.svg';
import linkedinBlueLogo from '../assets/svg/linkedinBlueIcon.svg';
import twitterLogo from '../assets/svg/twitter.svg';
import twitterBlueLogo from '../assets/svg/twitterBlueIcon.svg';

function HeaderSocialMediaLinks({ color }) {
	const icons = color === 'blue' ?
		{
			linkedin: linkedinBlueLogo,
			facebook: facebookBlueLogo,
			twitter: twitterBlueLogo
		} :
		{
			linkedin: linkedinLogo,
			facebook: facebookLogo,
			twitter: twitterLogo
		};

	return (
		<div className="social-media-wrapper">
			<a href="https://www.linkedin.com/company/tecnologiasgallorojo" rel="noreferrer" target="_blank">
				<img src={icons.linkedin} alt="Logo de linkedin" />
			</a>
			<a href="https://www.facebook.com/gallorojo.ti/" rel="noreferrer" target="_blank">
				<img src={icons.facebook} alt="Logo de facebook" />
			</a>
			<a href="https://twitter.com/gallorojoti" rel="noreferrer" target="_blank">
				<img src={icons.twitter} alt="Logo de twitter" />
			</a>
		</div>
	);
}

export default HeaderSocialMediaLinks;