import { useTranslation } from 'react-i18next';
import processConsultingImg from '../assets/svg/process-consulting.svg';

const ProcessConsultingInfo = () => {
	const { t } = useTranslation('ProcessConsultingInfo');
	return (
		<section className="process-consulting-info">
			<div className="container">
				<div className="row">
					<div className="image-wrapper">
						<img src={processConsultingImg} alt="" />
					</div>

					<div className="content-wrapper">
						<p>{t('text-1')}</p>
					</div>
				</div>
			</div>
		</section>
	);
}

export default ProcessConsultingInfo;