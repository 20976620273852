import { useTranslation } from 'react-i18next';
import processConsultingImg from '../assets/svg/process-consulting.svg';
import resumeIcon from '../assets/svg/resumeIcon.svg';
import Banner from "../components/Banner";
import ScrollToTop from '../components/ScrollToTop';
import SimpleContentBlock from '../components/SimpleContentBlock';
import SolutionsCrossLinking from '../components/SolutionsCrossLinking';
import useDocumentTitle from '../hooks/useDocumentTitle';
import { ROUTES } from '../routes';
import ImprovementControlInfo from '../sections/ImprovementControlInfo';
import ImprovementControlSection from '../sections/ImprovementControlSection';

function ImprovementControl() {
	const { t } = useTranslation('ImprovementControl');
	useDocumentTitle(t('title'));

	return (
		<div>
			<ScrollToTop />

			<Banner
				title={t('title')}
				ctaText={t('cta-text')}
				ctaLink={ROUTES.contactUs}
			/>

			<ImprovementControlInfo />

			<ImprovementControlSection />

			<SimpleContentBlock
				title={t('summary-heading')}
				icon={resumeIcon}
			>
				<p>{t('summary-text-1')}</p>
				<p>{t('summary-text-2')}</p>
			</SimpleContentBlock>

			<SolutionsCrossLinking
				title={t('cross-linking-title')}
				image={processConsultingImg}
				ctaText={t('cross-linking-cta-text')}
				ctaLink={ROUTES.solutions.processConsulting}
			/>
		</div>
	);
}

export default ImprovementControl;