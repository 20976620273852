import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import careerIcon from '../assets/svg/career-icon.svg';
import Banner from "../components/Banner";
import SimpleContentBlock from "../components/SimpleContentBlock";
import useDocumentTitle from '../hooks/useDocumentTitle';
import MissionAndVision from "../sections/MissionAndVision";
import OurCulture from "../sections/OurCulture";

function About() {
	const { t } = useTranslation('About');
	useDocumentTitle(t('pageTitle'));
	const { hash } = useLocation();

	const careerRef = useRef(null);
	const missionRef = useRef(null);
	const visionRef = useRef(null);
	const cultureRef = useRef(null);

	const [target, setTarget] = useState(null);
	const OFFSET_TOP_THRESHOLD = 100;

	useEffect(() => {
		switch (hash) {
			case '#mission':
				missionRef.current &&
					setTarget(missionRef.current);
				break;
			case '#vision':
				visionRef.current &&
					setTarget(visionRef.current);
				break;
			case '#culture':
				cultureRef.current &&
					setTarget(cultureRef.current);
				break;
			case '#career':
				careerRef.current &&
					setTarget(careerRef.current);
				break;
			default:
				break
		}
	}, [hash]);

	useEffect(() => {
		target ?
			window.scrollTo(0, target.offsetTop - OFFSET_TOP_THRESHOLD) :
			window.scrollTo(0, 0);
	}, [target]);

	return (
		<div>
			<Banner
				title={t('bannerTitle')}
				description={t('bannerDescription')}
			/>

			<MissionAndVision missionRef={missionRef} visionRef={visionRef} />

			<OurCulture cultureRef={cultureRef} />

			<div ref={careerRef}>
				<SimpleContentBlock
					title={t('heading')}
					icon={careerIcon}
				>
					<p>{t('text-1')}</p>
					<p>{t('text-2')}</p>
					<p>{t('text-3')}</p>
				</SimpleContentBlock>
			</div>
		</div>
	);
}

export default About;