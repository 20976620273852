import { useTranslation } from 'react-i18next';
import improvementControlInfoImg from '../assets/svg/improvement-control-info.svg';
import resumeIcon from '../assets/svg/resumeIcon.svg';
import Banner from "../components/Banner";
import ScrollToTop from '../components/ScrollToTop';
import SimpleContentBlock from '../components/SimpleContentBlock';
import SolutionsCrossLinking from '../components/SolutionsCrossLinking';
import useDocumentTitle from '../hooks/useDocumentTitle';
import { ROUTES } from '../routes';
import ProcessConsultingInfo from '../sections/ProcessConsultingInfo';
import ProcessConsultingTabs from '../sections/ProcessConsultingTabs';

function ProcessConsulting() {
	const { t } = useTranslation('ProcessConsulting');
	useDocumentTitle(t('title'));

	return (
		<div>
			<ScrollToTop />

			<Banner
				title={t('title')}
				ctaText={t('cta-text')}
				ctaLink={ROUTES.contactUs}
			/>

			<ProcessConsultingInfo />

			<ProcessConsultingTabs />

			<SimpleContentBlock
				title={t('summary-heading')}
				icon={resumeIcon}
			>
				<p>{t('summary-text-1')}</p>
				<p>{t('summary-text-2')}</p>
			</SimpleContentBlock>

			<SolutionsCrossLinking
				title={t('cross-linking-title')}
				image={improvementControlInfoImg}
				ctaText={t('cross-linking-cta-text')}
				ctaLink={ROUTES.solutions.improvementAndControl}
			/>
		</div>
	);
}

export default ProcessConsulting;