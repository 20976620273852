
import { useEffect } from 'react';

export default function useDocumentTitle(pageTitle) {
	const defaultTitle = 'Gallo Rojo';
	const titlePrefix = 'Gallo Rojo | ';

	const title = pageTitle ?
		titlePrefix + pageTitle :
		defaultTitle;

	useEffect(() => {
		document.title = title;
	}, [title]);
}