import { Trans, useTranslation } from 'react-i18next';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import automationImg from '../assets/automation.png';
import modelingImprovementImg from '../assets/modeling-improvement.png';
import statisticAnalysisImg from '../assets/statistic-analysis.png';

const ProcessConsultingTabs = () => {
	const { t } = useTranslation('ProcessConsultingTabs');
	return (
		<section className="process-consulting-tabs">
			<div className="container">
				<div className="row">
					<Tabs>
						<TabList>
							<Tab>{t('tab-title-1')}</Tab>
							<Tab>{t('tab-title-2')}</Tab>
							<Tab>{t('tab-title-3')}</Tab>
						</TabList>

						<TabPanel>
							<div className="container">
								<div className="row">
									<img className="left" src={automationImg} alt="" />
									<div className="content-wrapper">
										<Trans i18nKey="tab-content-1">
											<h1>{t('tab-title-1')}</h1>
											<p>{t('tab-content-1')}</p>
										</Trans>
									</div>
								</div>
							</div>
						</TabPanel>
						<TabPanel>
							<div className="container">
								<div className="row">
									<img className="right" src={modelingImprovementImg} alt="" />
									<div className="content-wrapper">
										<Trans i18nKey="tab-content-2">
											<h1>{t('tab-title-2')}</h1>
											<p>{t('tab-content-2')}</p>
										</Trans>
									</div>
								</div>
							</div>
						</TabPanel>
						<TabPanel>
							<div className="container">
								<div className="row">
									<img className="left" src={statisticAnalysisImg} alt="" />
									<div className="content-wrapper">
										<Trans i18nKey="tab-content-3">
											<h1>{t('tab-title-3')}</h1>
											<p>{t('tab-content-3')}</p>
										</Trans>
									</div>
								</div>
							</div>
						</TabPanel>
					</Tabs>
				</div>
			</div>
		</section>
	);
}

export default ProcessConsultingTabs;