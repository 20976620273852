import { Trans, useTranslation } from 'react-i18next';
import improvementControlInfoImg from '../assets/svg/improvement-control-info.svg';

const ImprovementControlInfo = () => {
	const { t } = useTranslation('ImprovementControlInfo');
	return (
		<section className="improvement-control-info">
			<div className="container">
				<div className="row">
					<div className="content-wrapper">
						<p>{t('text-1')}</p>
					</div>
					<div className="image-wrapper">
						<img src={improvementControlInfoImg} alt="" />
					</div>
				</div>

				<div className="row">
					<div className="content-wrapper left">
						<Trans i18nKey="text-2">
							<p>{t('text-2')}</p>
						</Trans>
					</div>
					<div className="content-wrapper right">
						<Trans i18nKey="text-3">
							<p>{t('text-3')}</p>
						</Trans>
					</div>
				</div>
			</div>
		</section>
	);
}

export default ImprovementControlInfo;