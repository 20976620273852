import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationsEn from './locales/en/translations.json';
import translationsEs from './locales/es/translations.json';
import MissionAndVisionEn from './locales/en/mission-and-vision.json';
import MissionAndVisionEs from './locales/es/mission-and-vision.json';
import OurCultureEn from './locales/en/our-culture.json';
import OurCultureEs from './locales/es/our-culture.json';
import AboutEn from './locales/en/about.json';
import AboutEs from './locales/es/about.json';
import ImprovementControlInfoEn from './locales/en/improvement-control-info.json';
import ImprovementControlInfoEs from './locales/es/improvement-control-info.json';
import ImprovementControlEn from './locales/en/improvement-control.json';
import ImprovementControlEs from './locales/es/improvement-control.json';
import ImprovementControlSectionEn from './locales/en/improvement-control-section.json';
import ImprovementControlSectionEs from './locales/es/improvement-control-section.json';
import ProcessConsultingEn from './locales/en/process-consulting.json';
import ProcessConsultingEs from './locales/es/process-consulting.json';
import ProcessConsultingInfoEn from './locales/en/process-consulting-info.json';
import ProcessConsultingInfoEs from './locales/es/process-consulting-info.json';
import ProcessConsultingTabsEn from './locales/en/process-consulting-tabs.json';
import ProcessConsultingTabsEs from './locales/es/process-consulting-tabs.json';
import ContactEn from './locales/en/contact.json';
import ContactEs from './locales/es/contact.json';

i18n.use(initReactI18next).init({
  fallbackLng: 'es',
  debug: true,
  lng: 'es',
  resources: {
    en: {
      translations: translationsEn,
      MissionAndVision: MissionAndVisionEn,
      OurCulture: OurCultureEn,
      About: AboutEn,
      ImprovementControl: ImprovementControlEn,
      ImprovementControlInfo: ImprovementControlInfoEn,
      ImprovementControlSection: ImprovementControlSectionEn,
      ProcessConsulting: ProcessConsultingEn,
      ProcessConsultingInfo: ProcessConsultingInfoEn,
      ProcessConsultingTabs: ProcessConsultingTabsEn,
      Contact: ContactEn,
    },
    es: {
      translations: translationsEs,
      MissionAndVision: MissionAndVisionEs,
      OurCulture: OurCultureEs,
      About: AboutEs,
      ImprovementControl: ImprovementControlEs,
      ImprovementControlInfo: ImprovementControlInfoEs,
      ImprovementControlSection: ImprovementControlSectionEs,
      ProcessConsulting: ProcessConsultingEs,
      ProcessConsultingInfo: ProcessConsultingInfoEs,
      ProcessConsultingTabs: ProcessConsultingTabsEs,
      Contact: ContactEs,
    }
  },
  ns: [
    'translations',
    'MissionAndVision',
    'OurCulture',
    'About',
    'ImprovementControl',
    'ImprovementControlInfo',
    'ImprovementControlSection',
    'ProcessConsulting',
    'ProcessConsultingInfo',
    'ProcessConsultingTabs',
    'Contact',
  ],
  defaultNS: 'translations'
});

i18n.languages = ['en', 'es'];

export default i18n;