import { useTranslation } from 'react-i18next';
import ActionButton from '../components/ActionButton';
import { ROUTES } from '../routes';
import aboutSvg from './../assets/svg/about.svg';
import operationSvg from './../assets/svg/operation.svg';

const AboutAndOperationSection = () => {
	const { t } = useTranslation();
	return (
		<section className="about-and-operation-section">
			<div className="container">
				<div className="row about">
					<div className="image-wrapper">
						<img src={aboutSvg} alt="" />
					</div>
					<div className="content-wrapper">
						<h6>{t('about-company')}</h6>
						<h1>{t('info-1')}<span className="highlighted">{t('info-2')}</span></h1>
						<p>{t('info-3')}</p>

						<ActionButton
							text={t('know-more')}
							link={ROUTES.about}
							inverted
						/>
					</div>
				</div>

				<div className="row operation">
					<div className="image-wrapper">
						<img src={operationSvg} alt="" />
					</div>
					<div className="content-wrapper">
						<h6>{t('function')}</h6>
						<h1><span className="highlighted">{t('function-info-1')} </span>{t('function-info-2')}</h1>
						<p>{t('function-info-3')}</p>
					</div>
				</div>
			</div>
		</section>
	);
}

export default AboutAndOperationSection;