function BreadCrumb({ text1, link1, text2, link2 }) {


	return (
		<div className='breadcrumb-text'>
			<a href={link1} className="breadcrumb">
				{text1}
			</a>
             <span className="separator">{' >> '}</span>
			<a href={link2} className="breadcrumb-2">
				{text2}
			</a>
		</div>


	);
}

export default BreadCrumb;