import { useTranslation } from 'react-i18next';
import PrivacyBox from '../components/PrivacyBox';

import ArcoForm from '../components/ArcoForm';
const ArcoFormSection = () => {
	const { t } = useTranslation();

	return (
		<section className="arco-form" id='arco-form'>
		  <div className="container">
			 <div className="row">
				 <div className="arco-form-wrapper">
					<ArcoForm/>
				 </div>
			 </div>
		  </div>
		</section>
	);
}

export default ArcoFormSection;