import { Trans, useTranslation } from 'react-i18next';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

const OurCulture = ({ cultureRef }) => {
	const { t } = useTranslation('OurCulture');

	return (
		<section ref={cultureRef} className="our-culture">
			<div className="container">
				<div className="row">
					<h1>{t('culture')}</h1>

					<Tabs>
						<TabList>
							<Tab>{t('tab-title-1')}</Tab>
							<Tab>{t('tab-title-2')}</Tab>
							<Tab>{t('tab-title-3')}</Tab>
						</TabList>

						<TabPanel>
							<Trans i18nKey="tab-content-1">
								<p>{t('tab-content-1')}</p>
							</Trans>
						</TabPanel>
						<TabPanel>
							<Trans i18nKey="tab-content-2">
								<p>{t('tab-content-2')}</p>
							</Trans>
						</TabPanel>
						<TabPanel>
							<Trans i18nKey="tab-content-3">
								<p>{t('tab-content-3')}</p>
							</Trans>
						</TabPanel>
					</Tabs>
				</div>
			</div>
		</section>
	);
}

export default OurCulture;