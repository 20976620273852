import { useTranslation } from 'react-i18next';
import ActionButton from '../components/ActionButton';
import HeroTypeWriter from '../components/HeroTypeWriter';
import { ROUTES } from '../routes';
import i18n from 'i18next';

const HeroSection = () => {
	const { t } = useTranslation();
	return (
		<section className="hero-section">
			<div id='type' className="container">
				<h6>{t('efective')}</h6>
				<HeroTypeWriter key={i18n.language} />				
				<ActionButton text={t('cta')} link={ROUTES.contactUs} />
			</div>
		</section>
	);
}

export default HeroSection;