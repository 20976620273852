import { useTranslation } from 'react-i18next';
import backgroundImg from '../assets/methodologiesBg.png';
import awsLogo from './../assets/svg/methodologies/awsLogo.svg';
import azureLogo from './../assets/svg/methodologies/azureLogo.png';
import blackALogo from './../assets/svg/methodologies/blackALogo.svg';
import centOsLogo from './../assets/svg/methodologies/centOsLogo.svg';
import ciscoLogo from './../assets/svg/methodologies/ciscoLogo.svg';
import coreOsLogo from './../assets/svg/methodologies/coreOsLogo.svg';
import debianLogo from './../assets/svg/methodologies/debianLogo.svg';
import dockerLogo from './../assets/svg/methodologies/dockerLogo.svg';
import f5Logo from './../assets/svg/methodologies/f5Logo.svg';
import fedoraLogo from './../assets/svg/methodologies/fedoraLogo.svg';
import gcpLogo from './../assets/svg/methodologies/gcpLogo.png';
import imbLogo from './../assets/svg/methodologies/imbLogo.svg';
import microsoftLogo from './../assets/svg/methodologies/microsoftLogo.png';
import oracleLogo from './../assets/svg/methodologies/oracleLogo.svg';
import redHatLogo from './../assets/svg/methodologies/redHatLogo.svg';
import suseLogo from './../assets/svg/methodologies/suseLogo.svg';
import ubuntuLogo from './../assets/svg/methodologies/ubuntuLogo.svg';

const MethodologiesSection = () => {
	const gradientImageBg = `radial-gradient(100% 100% at 15.6% 12.3%, rgba(24, 24, 61, 0.97) 8%, rgba(24, 24, 61, 0.82) 31%, rgba(24, 24, 61, 0.67) 54%, rgba(24, 24, 61, 0.361) 100%), url(${backgroundImg})`;
	const { t } = useTranslation();

	return (
		<section
			className="methodologies-section"
			style={{
				background: gradientImageBg,
				backgroundPosition: 'center center',
				backgroundSize: 'cover',
				backgroundRepeat: 'no-repeat'
			}}
		>
			<div className="container">
				<div className="row">
					<div className="content-wrapper">
						<div className="logos-area logos-left">
							<a href="https://www.ansible.com/" target="_blank" rel="noreferrer">
								<img className="black-a-logo" src={blackALogo} alt="ansible logo" />
							</a>
							<a href="https://www.debian.org/index.es.html" target="_blank" rel="noreferrer">
								<img className="debian-logo" src={debianLogo} alt="debian logo" />
							</a>
							<a href="https://www.redhat.com/es" target="_blank" rel="noreferrer">
								<img className="red-hat-logo" src={redHatLogo} alt="red hat logo" />
							</a>
							<a href="https://www.cisco.com/c/es_mx/index.html" target="_blank" rel="noreferrer">
								<img className="cisco-logo" src={ciscoLogo} alt="cisco logo" />
							</a>
							<a href="https://www.centos.org/" target="_blank" rel="noreferrer">
								<img className="cent-os-logo" src={centOsLogo} alt="cent os logo" />
							</a>
							<a href="https://www.oracle.com/mx/index.html" target="_blank" rel="noreferrer">
								<img className="oracle-logo with-bg" src={oracleLogo} alt="oracle logo" />
							</a>
							<a href="https://cloud.google.com/?hl=es" target="_blank" rel="noreferrer">
								<img className="gcp-logo with-bg" src={gcpLogo} alt="google cloud platform logo" />
							</a>
							<a href="https://www.suse.com/es-es/" target="_blank" rel="noreferrer">
								<img className="suse-logo with-bg" src={suseLogo} alt="suse logo" />
							</a>
							<a href="https://f5.com/es" target="_blank" rel="noreferrer">
								<img className="f5-logo with-bg" src={f5Logo} alt="f5 logo" />
							</a>
						</div>
						<div className="text">
							<h6 className="section-title">{t('metodologies')}</h6>
							<h1>{t('metodologies-info-3')} <span className="highlighted">{t('metodologies-info-1')}</span>{t('metodologies-info-2')}</h1>
							<h6 className="section-description">{t('metodologies-info-4')}</h6>
						</div>
						<div className="logos-area logos-right">
							<a href="https://aws.amazon.com/es/" target="_blank" rel="noreferrer">
								<img className="aws-logo" src={awsLogo} alt="aws logo" />
							</a>
							<a href="https://www.docker.com/" target="_blank" rel="noreferrer">
								<img className="docker-logo" src={dockerLogo} alt="docker logo" />
							</a>
							<a href="https://www.ubuntu.com/" target="_blank" rel="noreferrer">
								<img className="ubuntu-logo" src={ubuntuLogo} alt="ubuntu logo" />
							</a>
							<a href="https://getfedora.org/es/" target="_blank" rel="noreferrer">
								<img className="fedora-logo" src={fedoraLogo} alt="fedora logo" />
							</a>
							<a href="https://coreos.com/" target="_blank" rel="noreferrer">
								<img className="core-os-logo" src={coreOsLogo} alt="core os logo" />
							</a>
							<a href="https://www.microsoft.com/es-mx" target="_blank" rel="noreferrer">
								<img className="microsoft-logo with-bg" src={microsoftLogo} alt="microsoft logo" />
							</a>
							<a href="https://www.ibm.com/mx-es/" target="_blank" rel="noreferrer">
								<img className="imb-logo with-bg" src={imbLogo} alt="imb logo" />
							</a>
							<a href="https://azure.microsoft.com/es-mx/" target="_blank" rel="noreferrer">
								<img className="azure-logo with-bg" src={azureLogo} alt="azure logo" />
							</a>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export default MethodologiesSection;