import Typewriter from "typewriter-effect";
import { useTranslation } from 'react-i18next';

function HeroTypeWriter( {lang}) {
    const { t } = useTranslation();	
    
	return (
        <Typewriter
        options={{					
            autoStart: true,
            loop: true,
            cursor:''           
        }}

        onInit={(typewriter)=> {
        typewriter					
        .typeString(t('opex-ti'))						
        .pauseFor(1000)
        .deleteAll()
        .typeString(t('infrastructure'))
        .pauseFor(1000)
        .deleteAll()
        .typeString(t('memories'))
        .pauseFor(1000)
        .deleteAll()
        .typeString(t('devops'))
        .pauseFor(1000)
        .deleteAll()
        .typeString(t('saved'))
        .deleteAll()
        .typeString(t('operation'))
        .start();
        }}
        />

    
	);
   
}

export default HeroTypeWriter;


