import { useTranslation } from 'react-i18next';
import Breadcrumb from '../components/Breadcrumb';

const PrivacyPolicySection = () => {
	const { t } = useTranslation();

	return (
		<section className="breadcrumb">
			
				<Breadcrumb text1={t('home')} link1="/" text2={t('privacy')} link2="#" />				 
		

		</section>
	);
}

export default PrivacyPolicySection;