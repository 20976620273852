import ActionButton from "./ActionButton";

function SolutionsCrossLinking({ title, image, ctaText, ctaLink }) {
	return (
		<section className="solutions-cross-linking">
			<div className="container">
				<div className="row">
					<div className="shadowable">
						<div className="wrapper">
							<img src={image} alt="" />

							<div className="title-cta">
								<h1>{title}</h1>

								<ActionButton
									inverted
									text={ctaText}
									link={ctaLink}
								/>
							</div>
						</div>
						<div className="wrapper-shadow"></div>
					</div>
				</div>
			</div>
		</section>
	);
}

export default SolutionsCrossLinking;