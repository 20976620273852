import { useTranslation } from 'react-i18next';
import controlIcon from '../assets/svg/controlIcon.svg';
import improvementIcon from '../assets/svg/improvementIcon.svg';
import chevronIcon from '../assets/svg/chevron.svg'

const ImprovementControlSection = () => {
	const { t } = useTranslation('ImprovementControlSection');
	return (
		<section className="improvement-control-section">
			<div className="container">
				<div className="row">
					<div className="column improvement">
						<img src={improvementIcon} alt="" />
						<h1>{t('improvement-title')}</h1>

						<div className="content-wrapper">
							<p>{t('improvement-text')}</p>
						</div>
					</div>

					<img src={chevronIcon} alt="" />

					<div className="column control">
						<img src={controlIcon} alt="" />
						<h1>{t('control-title')}</h1>

						<div className="content-wrapper">
							<p>{t('control-text')}</p>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export default ImprovementControlSection;