import { useTranslation } from 'react-i18next';
import Banner from "../components/Banner";
import ScrollToTop from '../components/ScrollToTop';
import useDocumentTitle from "../hooks/useDocumentTitle";
import ContactFormSection from "../sections/ContactFormSection";

function ContactUs() {
	const { t } = useTranslation('Contact');
	useDocumentTitle(t('pageTitle'));

	return (
		<div>
			<ScrollToTop />

			<Banner title={t('bannerTitle')} />

			<ContactFormSection />
		</div>
	);
}

export default ContactUs;