import useDropdownMenu from 'react-accessible-dropdown-menu-hook';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from "react-router-dom";
import { ROUTES } from '../routes';

function DropdownMenu() {
	const { t } = useTranslation();
	const menuLabelText = t('solutions');
	const dropdownItems = [
		{ text: t('process-consulting'), href: ROUTES.solutions.processConsulting },
		{ text: t('improvement-and-control'), href: ROUTES.solutions.improvementAndControl },
	];

	const location = useLocation();
	const isSolutionsPage = location.pathname === ROUTES.solutions.improvementAndControl ||
		location.pathname === ROUTES.solutions.processConsulting;

	const { buttonProps, itemProps, setIsOpen, isOpen } = useDropdownMenu(dropdownItems.length);
	const menuWrapperClassname = `menu-wrapper ${isOpen ? ' visible' : ''}`;

	const dropdownMenuItems = dropdownItems.map((item, index) => {
		return (
			<NavLink {...itemProps[index]} key={index} to={item.href}>
				{item.text}
			</NavLink>
		)
	})

	return (
		<div
			className='use-dropdown-menu'
			onMouseOver={() => setIsOpen(true)}
			onMouseLeave={() => setIsOpen(false)}
		>
			<button
				className={isSolutionsPage ? 'active' : ''}
				{...buttonProps}
				type='button'
			>
				{menuLabelText}
			</button>

			<div className={menuWrapperClassname} role='menu'>
				{dropdownMenuItems}
			</div>
		</div>
	);
}

export default DropdownMenu;