import ActionButton from "./ActionButton";

const Banner = ({ title, description, ctaText, ctaLink }) => {
	return (
		<div className="banner">
			<h1>{title}</h1>

			<h3>{description}</h3>

			{ctaText && ctaLink &&
				<ActionButton text={ctaText} link={ctaLink} />
			}
		</div>
	);
}

export default Banner;