import { useMediaQuery } from "react-responsive";
import HeaderNavbar from "./HeaderNavbar";
import HeaderNavbarMobile from "./HeaderNavbarMobile";

function Header() {
	const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' })

	return isTabletOrMobile ? <HeaderNavbarMobile /> : <HeaderNavbar />;
}

export default Header;