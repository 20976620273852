import { saveAs } from "file-saver";

function DownloadButton({ text, link, name ,inverted, file }) {
	const wrapperClassName = inverted ?
		'white-bg cta-btn btn-wrapper' :
		'cta-btn btn-wrapper';

	const saveFile = () => {
		saveAs(
			file,
			name
		);
	};

	return (
		<a href={link} className={wrapperClassName}>
			<button onClick={saveFile}>
				<svg id="Group_9696" data-name="Group 9696" xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21">
					<path id="Path_5988" data-name="Path 5988" d="M0,0H21V21H0Z" fill="none" />
					<path id="Path_5989" data-name="Path 5989" d="M3,16.316H18V18H3Zm8.333-4.908,5.059-5.113,1.178,1.191L10.5,14.632,3.429,7.486,4.608,6.295l5.059,5.112V2h1.667Z" fill="#fff" />
				</svg>
				{text}
			</button>
		</a>
	);
}

export default DownloadButton;