import { Link } from "react-router-dom";
import headerLogo from '../assets/svg/header-logo.svg';
import { ROUTES } from '../routes';

function HeaderLogo() {
	return (
		<Link className="header-logo" to={ROUTES.home}>
			<img src={headerLogo} alt="" />
		</Link>
	);
}

export default HeaderLogo;