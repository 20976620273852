import React from 'react';
import { hydrate, render } from "react-dom";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import App from './App';
import './i18n/config';
import About from './pages/About';
import ContactUs from './pages/ContactUs';
import Home from './pages/Home';
import ImprovementControl from './pages/ImprovementControl';
import PrivacyNotice from './pages/PrivacyNotice';
import ProcessConsulting from './pages/ProcessConsulting';
import reportWebVitals from './reportWebVitals';
import { ROUTES } from './routes';

const rootElement = document.getElementById("root");

const application = (
	<React.StrictMode>
		<BrowserRouter>
			<Routes>
				<Route path={ROUTES.home} element={<App />} >
					<Route path="" element={<Home />} />
					<Route path={ROUTES.about} element={<About />} />
					<Route path={ROUTES.contactUs} element={<ContactUs />} />
					<Route path={ROUTES.solutions.improvementAndControl} element={<ImprovementControl />} />
					<Route path={ROUTES.solutions.processConsulting} element={<ProcessConsulting />} />
					<Route path={ROUTES.privacyNotice} element={<PrivacyNotice />} />
				</Route>
			</Routes>
		</BrowserRouter>
	</React.StrictMode>
);

if (rootElement.hasChildNodes()) {
	hydrate(application, rootElement);
} else {
	render(application, rootElement);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
