import { Trans, useTranslation } from 'react-i18next';
import PrivacyBox from '../components/PrivacyBox';
import DownloadButton from '../components/DownloadButton';

const PrivacyPolicySection = () => {
	const { t } = useTranslation();

	return (
		<section className="privacy">
			<div className="container">
				<div className="heading">
					<h1>{t('privacy')}</h1>
					<div className="privacy-info">
						{t('privacy-info')}
						<span>{t('privacy-date')}</span>
					</div>
				</div>

				<div className="full-text content-wrapper left">
					<p>
						<Trans i18nKey="privacy-text">
							{t('privacy-text')}
						</Trans>
					</p>
					<DownloadButton text={t('download')} link="#" name="Aviso de privacidad" file="Aviso.pdf" />

				</div>

				<div className="privacy-box content-wrapper right">
					<PrivacyBox />

				</div>
			</div>
		</section>
	);
}

export default PrivacyPolicySection;