import agilitySvg from './../assets/svg/agility.svg';
import devopsSvg from './../assets/svg/devops.svg';
import qualitySvg from './../assets/svg/quality.svg';
import { useTranslation } from 'react-i18next';

const OurPilarsSection = () => {
	const { t } = useTranslation();	
	return (
		<section className="our-pilars-section">
			<div className="container">
				<div className="row">
					<h1 className="our-pilars-heading">{t('our')}<span className="highlighted">{t('pilars')}</span></h1>
				</div>
				<div className="row">
					<div className="pilar-colums">
						<div className="column">
							<img src={qualitySvg} alt="" className="icon" />

							<h3 className="pilar-name">{t('quality')}</h3>

							<ul className="pilar-details">
								<li>{t('quality-1')}</li>
								<li>{t('quality-2')}</li>
								<li>{t('quality-3')}</li>
								<li>{t('quality-4')}</li>
								<li>{t('quality-5')}</li>
								<li>{t('quality-6')}</li>
								<li>{t('quality-7')}</li>
							</ul>
						</div>
						<div className="column">
							<img src={agilitySvg} alt="" className="icon" />

							<h3 className="pilar-name">{t('agility')}</h3>

							<ul className="pilar-details">
								<li>{t('agility-1')}</li>
								<li>{t('agility-2')}</li>
								<li>{t('agility-3')}</li>
								<li>{t('agility-4')}</li>
								<li>{t('agility-5')}</li>
								<li>{t('agility-6')}</li>
							</ul>
						</div>
						<div className="column">
							<img src={devopsSvg} alt="" className="icon" />

							<h3 className="pilar-name">DevOps</h3>

							<ul className="pilar-details">
								<li>{t('devops-1')}</li>
								<li>{t('devops-2')}</li>
								<li>{t('devops-3')}</li>
								<li>{t('devops-4')}</li>
								<li>{t('devops-5')}</li>
								<li>{t('devops-6')}</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export default OurPilarsSection;