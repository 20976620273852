import { useTranslation } from 'react-i18next';
import ScrollToTop from '../components/ScrollToTop';
import useDocumentTitle from "../hooks/useDocumentTitle";
import ArcoFormSection from "../sections/ArcoFormSection";
import Breadcrumb from "../sections/BreadcrumbSection";
import PrivacyPolicySection from "../sections/PrivacyPolicySection";

function PrivacyNotice() {
	const { t } = useTranslation();
	useDocumentTitle(t('privacy'));

	return (
		<div>
			<ScrollToTop />

			<Breadcrumb />

			<PrivacyPolicySection />

			<ArcoFormSection />

		</div>
	);
}

export default PrivacyNotice;