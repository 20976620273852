import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';


function LanguageSwitcher() {
	const { i18n } = useTranslation();
	const [language, setLanguage] = useState('es');

	const toggleLanguage = () => {
		setLanguage((prev) => prev === 'es' ? 'en' : 'es');	};

	useEffect(() => {
		i18n.changeLanguage(language);
	}, [language]);

	return (
		<div className='language-container'>
			<button
				title="Change language"
				className='language-selector'
				onClick={toggleLanguage}
			>
				{language}
			</button>
		</div>
	);
}

export default LanguageSwitcher;


