import { Outlet } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./sections/FooterSection";
import './styles/main.scss';
import { useLocation } from 'react-router-dom'


function App() {
	const location = useLocation();
	return (
		<div className="App" location={location.pathname} id="top">
			<Header />

			<Outlet />

			<Footer />
		</div>
	);
}

export default App;
