import { useTranslation } from 'react-i18next';
import missionImg from '../assets/mission.png';
import visionImg from '../assets/vision.png';

const MissionAndVision = ({ missionRef, visionRef }) => {
	const { t } = useTranslation('MissionAndVision');

	return (
		<section className="mission-and-vision">
			<div className="container">
				<div ref={missionRef} className="row mission">
					<div className="image-wrapper">
						<img src={missionImg} alt="" />
					</div>
					<div className="content-wrapper">
						<h1>{t('mission-heading')}</h1>
						<p>{t('mission-content')}</p>
					</div>
				</div>

				<div ref={visionRef} className="row vision">
					<div className="image-wrapper">
						<img src={visionImg} alt="" />
					</div>
					<div className="content-wrapper">
						<h1>{t('vision-heading')}</h1>
						<p>{t('vision-content')}</p>
					</div>
				</div>
			</div>
		</section>
	);
}

export default MissionAndVision;