import debounce from 'lodash.debounce';
import { useCallback, useEffect, useState } from 'react';

export default function useIsHeaderAtTop() {
	const SCROLL_THRESHOLD = 100;
	const DEBOUNCE_TIME = 150;

	const [isHeaderAtTop, setIsHeaderAtTop] = useState(false);

	const debouncedScrollHandler = useCallback(
		debounce(
			element => {
				setIsHeaderAtTop(element.scrollY - SCROLL_THRESHOLD <= 0);
			}, DEBOUNCE_TIME), []
	);

	const scrollHandler = (e) => debouncedScrollHandler(e.currentTarget);

	useEffect(() => {
		setIsHeaderAtTop(window.scrollY - SCROLL_THRESHOLD <= 0);

		window.addEventListener("scroll", scrollHandler);

		return () => {
			window.removeEventListener("scroll", scrollHandler);
		}
	}, []);

	return { isHeaderAtTop };
}