import { HashLink as Link } from 'react-router-hash-link';

function ActionButton({ text, link = '#', inverted, ...props }) {
	const wrapperClassName = inverted ?
		'white-bg cta-btn btn-wrapper' :
		'cta-btn btn-wrapper';

	return (
		<Link to={link} className={wrapperClassName} {...props}>
			<button>
				{text}
			</button>
		</Link>
	);
}

export default ActionButton;