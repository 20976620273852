import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from "react-router-dom";
import closeIcon from '../assets/svg/close-menu-icon.svg';
import hamburguerIcon from '../assets/svg/hamburguer-menu-icon.svg';
import ActionButton from "../components/ActionButton";
import useIsHeaderAtTop from '../hooks/useIsHeaderAtTop';
import { ROUTES } from '../routes';
import HeaderLogo from './HeaderLogo';
import HeaderSocialMediaLinks from './HeaderSocialMediaLinks';
import LanguageSwitcher from './LanguageSwitcher';

function HeaderNavbarMobile() {
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const { isHeaderAtTop } = useIsHeaderAtTop();
	const { t } = useTranslation();

	const openMenu = () => {
		setIsMenuOpen(true);
	}

	const closeMenu = () => {
		setIsMenuOpen(false);
	}

	return (
		<header className={isHeaderAtTop ? 'at-top' : ''}>
			<HeaderLogo />

			<button
				className="open-menu"
				onClick={openMenu}
			>
				<img src={hamburguerIcon} alt="Open side menu" />
			</button>


			<div className={`menu-panel ${isMenuOpen ? 'open' : 'closed'}`}>
				<button
					className="close-menu"
					onClick={closeMenu}
				>
					<img src={closeIcon} alt="Close side menu" />
				</button>

				<div className="wrapper">
					<nav className='header-navbar-mobile'>
						<NavLink to={ROUTES.home} onClick={closeMenu}>
							{t('home')}
						</NavLink>
						<NavLink to={ROUTES.about} onClick={closeMenu}>
							{t('company')}
						</NavLink>
						<NavLink to={ROUTES.solutions.processConsulting} onClick={closeMenu}>
							{t('process-consulting')}
						</NavLink>
						<NavLink to={ROUTES.solutions.improvementAndControl} onClick={closeMenu}>
							{t('improvement-and-control')}
						</NavLink>
						<NavLink to={ROUTES.contactUs} onClick={closeMenu}>
							{t('contact')}
						</NavLink>

						<LanguageSwitcher />
					</nav>

					<ActionButton
						text={t('cta')}
						link={ROUTES.contactUs}
						onClick={closeMenu}
					/>

					<HeaderSocialMediaLinks color='blue' />
				</div>
			</div>
		</header>
	);
}

export default HeaderNavbarMobile;