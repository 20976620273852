import ActionButton from '../components/ActionButton';
import { useRef, useState } from 'react';
import storage from '../firebase';
import InfoMessage from '../components/InfoMessage';
import { useTranslation } from 'react-i18next';
import emailjs from '@emailjs/browser';

function ArcoForm() {
	const { t } = useTranslation();
	const [status, setStatus] = useState('');
	const [validate, setValidate] = useState('');
	const [image, setImage] = useState('');
	const upload = () => {
		
		if (image == null)
			return;
		storage.ref(`/ARCO/${image.name}`).put(image)
			.on("state_changed", console.log("success"), alert);
	}

	const form = useRef();
	const ref = useRef();
	const validateEmail = (email) => {
		return email.match(
			/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		);
	};
	const validation = () => {
		var send = false;
		if (form.current.elements.email.value == ""
			|| form.current.elements.name.value == ""
			|| form.current.elements.phone.value == ""
			|| form.current.elements.motivo.value == ""
			|| form.current.elements.derechos.value == '0'
			|| form.current.elements.fecha.value == "") {
			setStatus({ type: 'error', message: 'mandatory' });
		}

		else if (form.current.elements.email.value !== "" && !validateEmail(form.current.elements.email.value)) {
			setStatus({ type: 'error', message: 'invalid-email' });
		}
		else if (!form.current.elements.check.checked) {
			setStatus({ type: 'error', message: 'accept-policy' });
		}

		else {
			send = true;
		}
		return send;
	}
	const handleSubmit = event => {
		event.preventDefault();
		upload();
		var send = validation();
		if (send) {
			var url = "https://firebasestorage.googleapis.com/v0/b/gallo-rojo-website.appspot.com/o/ARCO%2f" + image.name + "?alt=media&token=5cdfaf2a-c378-4da5-aa7f-26202dd8cd45";
			form.current.elements.attachment_url.value = url;
			form.current.elements.arco_file.value =null;
			emailjs.sendForm('service_a7aaxes', 'template_6ow9bpf', form.current, 'user_P4gZ3UyJaGE59pB5Lg9pZ')
				.then((result) => {
					setStatus({ type: 'success', message: 'message-sent' });
				}, (error) => {
					setStatus({ type: 'error', message: 'message-error' });
				});
			form.current.reset();
		}
	}
	return (
		<div className="arco-form">
			<div className="container" id='form-arco'>
				{status?.type === 'success' && <InfoMessage message={t(status.message)} type="sucess" />}
				{status?.type === 'error' && <InfoMessage message={t(status.message)} type="error" />}
				<div className="row">
					<div className="content-wrapper">
						<h1> {t('send-arco')}</h1>
						<form id='arco' ref={form}>
							<div className="form-wrapper">
								<h6 className="form-title datos-headline">{t('personal-data')}</h6>
								<input className="full-name" type="text" name="name" placeholder={t('full-name')} />
								<input className="email" type="email" name="email" placeholder={t('email')} />
								<input className="address" type="text" name="domicilio" placeholder={t('info-a')} />
								<input className="phone" type="number" name="phone" placeholder={t('phone-2')} />
								<h6 className="form-title rights-headline">{t('arco-right')}</h6>
								{/* <input className="rights-input" type="text" name="derechos" placeholder={t('right-ejer')} /> */}
								<select className="rights-input" name="derechos" id="cars">
									<option value='0'>{t('right-ejer')}</option>
									<option value={t('right-access')}>{t('right-access')}</option>
									<option value={t('right-rect')}>{t('right-rect')}</option>
									<option value={t('right-cancel')}>{t('right-cancel')}</option>
									<option value={t('right-op')}>{t('right-op')}</option>
								</select>
								<input className="date" ref={ref} onFocus={() => (ref.current.type = "date")}
									onBlur={() => (ref.current.type = "text")} type="text" name="fecha" placeholder={t('date-arco')} />
								<textarea className="reason" name="motivo" placeholder={t('motive')}></textarea>

								<input className="attachment" type="file" name="arco_file" onChange={(e) => { setImage(e.target.files[0]) }} ></input>


								<input className="attachment-link" type="hidden" name="attachment_url" />
								<input className="download-link" type="text" name="link" placeholder={t('link-download')} />
								<div className="agree-container agree">
									<input type="checkbox" id="agree" name="check"></input>
									<label className="agree" htmlFor="agree">{t('arco-accept')}</label>
								</div>

								<div className="submit">
									<ActionButton onClick={handleSubmit} form='arco' type="submit" text={t('send-sol')} />
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>


	);
}

export default ArcoForm;