function SimpleContentBlock({ icon, title, children }) {
	return (
		<section className="simple-content-block">
			<div className="container">
				<div className="row">
					<img src={icon} alt="" />
					<h1>{title}</h1>

					<div className="content-wrapper">
						{children}
					</div>
				</div>
			</div>
		</section>
	);
}

export default SimpleContentBlock;