import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import facebookLogo from '../assets/svg/facebook.svg';
import footerLogo from '../assets/svg/footer-logo.svg';
import goUpIcon from '../assets/svg/go-up-arrow.svg';
import linkedinLogo from '../assets/svg/linkedin.svg';
import twitterLogo from '../assets/svg/twitter.svg';
import ActionButton from "../components/ActionButton";
import { ROUTES } from '../routes';

const Footer = () => {
	const { t } = useTranslation();
	return (
		<footer className="footer-section">
			<section className="pre-footer-section">
				<div className="container">
					<div className="big-cta-wrapper">
						<h1><span className="reverse-highlighted">{t('dedication')}</span> {t('standars')}</h1>

						<p>{t('plans')}</p>

						<ActionButton inverted text={t('cta')} link="/contact-us" />
					</div>
				</div>
			</section>

			<div className="container">
				<div className="footer-content">
					<div className="logo-up-wrapper">
						<img src={footerLogo} className="footer-logo" alt="Logo de Gallo Rojo" />
						<a href="#top" className="go-up-button">
							<img src={goUpIcon} alt="" />
						</a>
					</div>

					<div className="footer-grid-area">
						<div className="contact-info">
							<a href="mailto:contacto@gallorojo.com.mx">
								contacto@gallorojo.com.mx
							</a>
							<a href="tel:(55) 2891 8282">
								(55) 2891 8282
							</a>
						</div>
						<div className="copyright">
							<span>Copyright © Gallo Rojo.</span>
							<span>{t('copyright')}</span>
						</div>
						<div className="social-media-links">
							<a href="https://www.linkedin.com/company/tecnologiasgallorojo" rel="noreferrer" target="_blank">
								<img src={linkedinLogo} alt="Logo de linkedin" />
							</a>
							<a href="https://www.facebook.com/gallorojo.ti/" rel="noreferrer" target="_blank">
								<img src={facebookLogo} alt="Logo de facebook" />
							</a>
							<a href="https://twitter.com/gallorojoti" rel="noreferrer" target="_blank">
								<img src={twitterLogo} alt="Logo de twitter" />
							</a>
						</div>
						<div className="company-solutions-other">
							<div className="footer-content-row">
								<h6>{t('company')}</h6>
								<nav>
									<Link to={{ pathname: ROUTES.about, hash: '#mission' }}>
										{t('mision')}
									</Link>
									<span className="separator">/</span>
									<Link to={{ pathname: ROUTES.about, hash: '#vision' }}>
										{t('vision')}
									</Link>
									<span className="separator">/</span>
									<Link to={{ pathname: ROUTES.about, hash: '#culture' }}>
										{t('culture')}
									</Link>
									<span className="separator">/</span>
									<Link to={{ pathname: ROUTES.about, hash: '#career' }}>
										{t('carrer')}
									</Link>
								</nav>
							</div>
							<div className="footer-content-row">
								<h6>{t('solutions')}</h6>
								<nav>
									<Link to={ROUTES.solutions.improvementAndControl}>
										{t('improvement-and-control')}
									</Link>
									<span className="separator">/</span>
									<Link to={ROUTES.solutions.processConsulting}>
										{t('process-consulting')}
									</Link>
								</nav>
							</div>
							<div className="footer-content-row">
								<h6>{t('others')}</h6>
								<nav>
									<Link to={ROUTES.privacyNotice}>
										{t('privacy')}
									</Link>
								</nav>
							</div>
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
}

export default Footer;