import { useTranslation } from 'react-i18next';
import DownloadButton from '../components/DownloadButton';
import ActionButton from '../components/ActionButton';

const PrivacyBox = () => {
	const { t } = useTranslation();

	return (
		<div className='privacy-box-container'>
			<div className='privacy-box-top'>
				<h3>{t('privacy-title-1')}</h3>
				<p>{t('privacy-description-1')}</p>
				<DownloadButton text={t('download')} name = "Politicas de Privacidad Integral.pdf" link="#" file="Aviso.pdf" />
			</div>
			<div className='privacy-box-bottom'>
				<div className="text">
					{t('privacy-description-2')}
				</div>
				<div className="instructions">
					<h4>{t('instructions')}</h4>
					<div className="list">
						<div className="list-item">
							<span className='number'>1</span>
							<span className='option'>{t('option-1')}
								<a download href='/ARCO.pdf'>
									{t('option-1-2')}
								</a>
								{t('option-1-3')}
							</span>
						</div>
						<div className="list-item">
							<span className='number'>2</span>
							<span className='option'>{t('option-2')}</span>
						</div>
						<div className="list-item">
							<span className='number'>3</span>
							<span className='option'>
								{t('option-3')}
								<a href='#arco-form'>
									{t('option-3-1')}
								</a>
								{t('option-3-2')}
							</span>
						</div>
						<div className="list-item">
							<span className='number'>4</span>
							<span className='option'>{t('option-4')}</span>
						</div>
						<div className="list-item">
							<span className='number'>5</span>
							<span className='option'>{t('option-5')}</span>
						</div>
					</div>
					<DownloadButton text={t('download-2')} link="#"  name = "Formulario ARCO.pdf" file="ARCO.pdf" />
					<br />
					<ActionButton text={t('fill')} link="/privacy-notice#arco-form" inverted />
				</div>
			</div >

		</div >
	);
}

export default PrivacyBox;