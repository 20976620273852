import emailjs from '@emailjs/browser';
import { useRef, useState } from 'react';
import emailIcon from '../assets/svg/emailIcon.svg';
import phoneIcon from '../assets/svg/phoneIcon.svg';
import ActionButton from '../components/ActionButton';
import InfoMessage from '../components/InfoMessage';
import { useTranslation } from 'react-i18next';

const ContactFormSection = () => {
	const { t } = useTranslation();	
	const [status, setStatus] = useState(undefined);	
	const validateEmail = (email) => {
		return email.match(
		  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		);
	  };
	const validation = () => {
		var send = false;
		if(form.current.elements.email.value == "" ||  form.current.elements.name.value == "")
		{
			setStatus({ type: 'error', message: 'mandatory' });
		}

		else if(form.current.elements.email.value !== "" && !validateEmail(form.current.elements.email.value)){
			setStatus({ type: 'error', message: 'invalid-email' });	
		}
		
		else{
			send = true;
		}
		return send;
   }
	const form = useRef();
	const handleSubmit = event => {
		event.preventDefault();
		var send = validation();
		if(send)
		{
		emailjs.sendForm('service_a7aaxes', 'template_ulw99vj', form.current, 'user_P4gZ3UyJaGE59pB5Lg9pZ')
			.then((result) => {
				setStatus({ type: 'success', message: 'message-sent'});
			}, (error) => {
				setStatus({ type: 'error', message: 'message-error'});
			});
			form.current.reset();
		}

	}

	return (
		<section className="contact-form">
			<div className="wrapper">
				<div className="container">
					{status?.type === 'success' && <InfoMessage message={t(status.message)} type="sucess" />}
					{status?.type === 'error' && <InfoMessage message={t(status.message)} type="error" />}
				

					<div className="row contact-form">
						<div className="text-wrapper">
							<p>
								{t('contact-1')}
								<br />
								<br />
								{t('contact-2')}
							</p>
							<div className='contact-info'>
								<div className='email-info'>
									<a href='mailto:contacto@gallorojo.com.mx'>
										<img src={emailIcon} alt="" />
										<span>contacto@gallorojo.com.mx</span>
									</a>
								</div>
								<div className="phone-number">
									<a href='tel:(55) 2891 8282' className='phone-info'>
										<img src={phoneIcon} alt="" />
										<span>(55) 2891 8282</span>
									</a>
								</div>
							</div>
						</div>
						<div className="form-wrapper">
							<form id='contact' ref={form} >
								<h6 className="form-title">{t('contact-3')}</h6>
								<div className="datos-container">
									<input className="name" type="text" name="name" placeholder={t('full-name')}/>

									<input className="company" type="text" name="compania" placeholder={t('company')} />

									<input className="email" type="email" name="email" placeholder={t('email')} />

									<input className="phone" type="number" name="phone" placeholder={t('phone')} />

									<textarea className="message" name="message" placeholder={t('message')}></textarea>

									<div className="submit-container submit">
										<ActionButton onClick={handleSubmit} form='contact' type="submit" text={t('send-message')} />
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export default ContactFormSection;