import firebase from 'firebase';
const firebaseConfig = {
  apiKey: "AIzaSyCAiiDnfzwLP7ZBgF1rxHKGozx6OXebe-o",
  authDomain: "gallo-rojo-website.firebaseapp.com",
  projectId: "gallo-rojo-website",
  storageBucket: "gallo-rojo-website.appspot.com",
  messagingSenderId: "957202538469",
  appId: "1:957202538469:web:e3f4adeefa6172e41b990c"
};
firebase.initializeApp(firebaseConfig);
var storage = firebase.storage();
export default storage;