import { useTranslation } from 'react-i18next';
import { NavLink } from "react-router-dom";
import ActionButton from "../components/ActionButton";
import useIsHeaderAtTop from '../hooks/useIsHeaderAtTop';
import { ROUTES } from '../routes';
import DropdownMenu from './DropdownMenu';
import HeaderLogo from './HeaderLogo';
import HeaderSocialMediaLinks from './HeaderSocialMediaLinks';
import LanguageSwitcher from "./LanguageSwitcher";

function HeaderNavbar() {
	const { isHeaderAtTop } = useIsHeaderAtTop();
	const { t } = useTranslation();

	return (
		<header className={isHeaderAtTop ? 'at-top' : ''}>
			<HeaderLogo />

			<nav className='header-navbar'>
				<NavLink to={ROUTES.about}>
					{t('company')}
				</NavLink>

				<DropdownMenu />

				<NavLink to={ROUTES.contactUs}>
					{t('contact')}
				</NavLink>

				<LanguageSwitcher />

				<HeaderSocialMediaLinks />
			</nav>

			<ActionButton text={t('cta')} link={ROUTES.contactUs} />
		</header>
	);
}

export default HeaderNavbar;